import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import IntroSection from "./introSection";
import SpecialEducationSection from "./specialEducation";
import TBControlSection from "./tbControl";
import LifeLongCare from "./lifeLongCare";

const OurFocusPage = (props) => {

  const data = useStaticQuery(graphql`
    query OurFocusPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "our-focus"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  return (
    <Layout activeLink="/our-focus" pageTitle={title} pageDescription={subTitle} {...props}>
       <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                            imageSrc={imageCaptionBackground}/>
      <IntroSection/>
      <SpecialEducationSection/>
      <TBControlSection/>
      <LifeLongCare/>
      <HelpMethodsSection/>
    </Layout>
  );
};

OurFocusPage.propTypes = {};

export default OurFocusPage;
