import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import FloatingSection from "../../components/floatingSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";

function TBControlSection() {
  const data = useStaticQuery(graphql`
    query TBControlSectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "our-focus"}}) {
        frontmatter {
          tbControlSection {
            title
            paragraphs
            tbControlCaptionImage
            tbControlImage1
            tbControlImage2
          }
        }
      }
    }
  `)
  const {
    title, paragraphs, tbControlCaptionImage, tbControlImage1, tbControlImage2,
  } = data.markdownRemark.frontmatter.tbControlSection;

  return (
    <div id="tuberculosis" className="mt-20">
      <ImageCaptionSection
        title={""} imageSrc={tbControlCaptionImage} imageAlt={title} mode="singleCaption" textColor="text-yellow-400"/>

      <FloatingSection>
        <div className="md:flex flex-row">
          <div className="flex-1 p-10 px-20">
            <EmphasisTextUnderlined strokeType="strokeSmallYellow" className="mt-10 w-36"/>
            <p className="mt-20 mb-10 text-2xl font-bold">{title}</p>
            <p className="text-justify mb-5">{paragraphs[0]}</p>
            <p className="text-justify mb-5">{paragraphs[1]}</p>
            <p className="text-justify mb-5">{paragraphs[2]}</p>
            <p className="text-justify">{paragraphs[3]}</p>
          </div>
          <div className="flex-1 relative min-h-[30rem] md:min-h-[25rem]">
            <div className="absolute bg-yellow-500 top-10 bottom-20 left-10 right-0"/>
            <img src={tbControlImage1} alt="Special Education"
                 className="absolute w-1/3 md:w-1/2 md:-translate-y-1/2 right-20"/>
            <img src={tbControlImage2} alt="Special Education"
                 className="absolute w-1/2 md:w-3/5 bottom-0"/>
          </div>
        </div>

      </FloatingSection>
    </div>
  );
}

export default TBControlSection;
