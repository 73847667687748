import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import FloatingSection from "../../components/floatingSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";

function LifeLongCareSection() {
  const data = useStaticQuery(graphql`
    query LifeLongCareSectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "our-focus"}}) {
        frontmatter {
          lifeLongCareSection {
            title
            paragraphs
            lifeLongCareCaptionImage
            lifeLongCareImage1
          }
        }
      }
    }
  `)
  const {
    title, paragraphs, lifeLongCareCaptionImage, lifeLongCareImage1,
  } = data.markdownRemark.frontmatter.lifeLongCareSection;

  return (
    <div id="lifelong-care" className="mt-20">
      <ImageCaptionSection
        title={""} imageSrc={lifeLongCareCaptionImage} imageAlt={title} mode="singleCaption" textColor="text-red-500"/>

      <FloatingSection>
        <div className="md:flex flex-row">
          <div className="flex-1 p-10 px-20">
            <EmphasisTextUnderlined strokeType="strokeSmallRed" className="mt-10 w-36"/>
            <p className="mt-20 mb-10 text-2xl font-bold">{title}</p>
            <p className="text-justify mb-5">{paragraphs[0]}</p>
            <p className="text-justify mb-5">{paragraphs[1]}</p>
          </div>
          <div className="flex-1 relative min-h-[32rem] md:min-h-[25rem]">
            <div className="absolute bg-red-500 top-10 bottom-20 left-10 w-1/2"/>
            <img src={lifeLongCareImage1} alt="Special Education"
                 className="absolute w-1/2 md:-translate-y-1/2 right-20"/>
          </div>
        </div>
      </FloatingSection>
    </div>
  );
}

export default LifeLongCareSection;
