import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import FloatingSection from "../../components/floatingSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";

function SpecialEducationSection() {
  const data = useStaticQuery(graphql`
    query SpecialEducationSectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "our-focus"}}) {
        frontmatter {
          specialEducationSection {
            title
            paragraphs
            specialEducationCaptionImage
            specialEducationImage1
            specialEducationImage2
          }
        }
      }
    }
  `)
  const {
    title, paragraphs, specialEducationCaptionImage, specialEducationImage1, specialEducationImage2,
  } = data.markdownRemark.frontmatter.specialEducationSection;

  return (
    <div id="special-education">
      <ImageCaptionSection
        title={""} imageSrc={specialEducationCaptionImage} imageAlt={title} mode="singleCaption" textColor="text-blue-300"/>

      <FloatingSection>
        <div className="md:flex flex-row">
          <div className="flex-1 pt-10 pb-20 px-20">
            <EmphasisTextUnderlined strokeType="strokeSmallBlue" className="mt-10 w-36"/>
            <p className="mt-20 mb-10 text-2xl font-bold">{title}</p>
            <p className="text-justify">{paragraphs[0]}</p>
          </div>
          <div className="flex-1 relative min-h-[30rem] md:min-h-[25rem]">
            <div className="absolute bg-blue-400 top-10 bottom-20 left-10 right-0"/>
            <img src={specialEducationImage1} alt="Special Education"
                 className="absolute w-1/3 md:w-1/2 md:-translate-y-1/2 right-20"/>
            <img src={specialEducationImage2} alt="Special Education"
                 className="absolute w-1/2 md:w-3/5 bottom-0"/>
          </div>
        </div>
      </FloatingSection>
    </div>
  );
}

export default SpecialEducationSection;
