import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import FloatingSection from "../../components/floatingSection";

function IntroSection() {
  const data = useStaticQuery(graphql`
    query IntroSection {
      markdownRemark(frontmatter: {templateKey: {eq: "our-focus"}}) {
        frontmatter {
          introSection {
            para
            bullets
          }
        }
      }
    }
  `)
  const {
    para, bullets
  } = data.markdownRemark.frontmatter.introSection;

  return (
    <div id="intro" className="bg-[url('../img/backgrounds/background5.jpg')] bg-[center_top_-60rem] bg-no-repeat">
      <FloatingSection>
        <div className="md:w-2/3 xl:w-1/2 mx-auto p-20">
          <p className="pb-5">{para}:</p>
          <ul>
            {bullets.map((bullet, index) => <li key={index} className="pb-5 ml-5 list-disc">{bullet}</li>)}
          </ul>
        </div>
      </FloatingSection>
    </div>
  );
}

export default IntroSection;
